import React, {useEffect, useState} from "react";
import {getDataByParams} from "../services/BaseService";
import { ordersUrls} from "../util/api_constant";
import {navigate} from "gatsby";
import {useForm} from "react-hook-form";
import {convertState, formatDate} from "../util/helper";
import {useMediaQuery} from "../util/media-query";

interface Parcel {
    id: number,
    departureCode: string,
    destinationCode: string,
    deliveryDate: string,
    state: string,
    website: string,
    progress: number
}
const getProgress = (state: string) => {
  switch (state) {
      case "EXPEDITED":
          return 40;
      case "ON_GOING_CLEARANCE":
          return 70;
      case "ON_DELIVERING":
          return 90;
      case "PAYMENT_PENDING":
          return 95;
      case "DELIVERED_TO_CUSTOMER":
          return 100;
      default :
          return 30;
  }
}
const TrackingContent = (props: any) => {
    const isMobile = useMediaQuery('(max-width: 850px)');
    const {register, handleSubmit, reset, formState: {errors}} = useForm<any>({
        mode: 'onTouched'
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [trackingNumber, setTrackingNumber] = useState<string>('');
    const [parcels, setParcels]= useState<Parcel[]>([])

    useEffect(() => {
        const {location} = props;
        if (location && location.pathname){
            if (location.search && location.search.indexOf("?tracking-number=") !== -1){
                let search: string = location.search;
                setTrackingNumber(search.replace("?tracking-number=", ""));
                if (search.replace("?tracking-number=", ""))
                    getExpeditionByTrackingNumber(search.replace("?tracking-number=", ""));
            }else {
                navigate(`${location.pathname}?tracking-number=`)
            }
        }

    },[props]);
    const getExpeditionByTrackingNumber = (trackingNumber:string) => {
        setIsSubmitting(true);
        getDataByParams(`${ordersUrls.tracking}/${trackingNumber}`)
            .then((res: any[]) => {
                let items: Parcel[] = [];
                if (res && res.length > 0){

                    res.forEach(item => {
                        if (item.products){
                            item.products.forEach((p: any) => {
                                items.push({
                                    id: p.id,
                                    state: p.state,
                                    progress: getProgress(p.state),
                                    deliveryDate: p.deliveryDate,
                                    website: p.website,
                                    departureCode: item.departureCode,
                                    destinationCode: item.destinationCode
                                })
                            })
                        }
                    })
                }
                setParcels(items);
                setIsSubmitting(false);
            // setUser(res);
            }).catch(()=>{
            setIsSubmitting(false);
        })
    }

    const onSubmit = async (data: any) => {
        const {location} = props;
        if (location && location.pathname) {
            navigate(`${location.pathname}?tracking-number=${data.trackingNumber}`)
        }
    };

    return <>
        <div>
            <div className="overflow-hidden">
                <div className="container content-space-1 content-space-lg-4">

                    <div className={`card card-lg ${isMobile?'mt-7':''}`}>
                        <h3 className="card-title text-center mt-2">Suivi de votre expédition</h3>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-4 mb-sm-0">
                                    <div className="mb-4">
                                        <input type="text" className="form-control" required
                                            defaultValue={trackingNumber}
                                               id="trackingNumber" placeholder="Renseignez votre numéro de suivi Afrisends ici"
                                               {...register("trackingNumber")}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                                <div className={'text-center'}>
                                    <button type="submit" className="btn btn-primary btn-lg w-50" disabled={isSubmitting}>
                                        {isSubmitting ? <div>
                                                <span className="spinner-border spinner-border-sm mr-3" role="status"
                                                                   aria-hidden="true"/>&nbsp;&nbsp;Envoi en cours</div> :
                                            <span>Envoyer</span>}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="card card-lg mt-7">

                        <div className="alert alert-soft-success text-center" role="alert">
                            <h3 className="card-title mt-2">Vos colis </h3>
                            <p>Retrouvez ici tous les colis liés à votre expédition</p>
                        </div>
                        <div className="card-body w-100">
                            {
                                (parcels.length === 0 && !isSubmitting)?
                                    <div className="alert alert-soft-danger text-center" role="alert">
                                        <h3 className="card-title mt-2">Aucun colis à afficher</h3>
                                        <p>{trackingNumber?<span> Nous n'avons trouvé aucun colis lié au numéro de suivi <strong>{trackingNumber}</strong></span>:'Veuillez renseigner un numéro de suivi'}</p>
                                    </div>:
                                    <div className="row">

                                        {
                                            parcels.map(p => (
                                                <div key={`parcel_${p.id}`} className="col-md-6 col-sm-12 col-xs-12 mb-2">
                                                    <div className="card border-primary">
                                                        <div style={{padding:'10px 10px'}} className="card-title d-flex flex-row justify-content-between align-items-center">
                                                            <h4 title={p.website} style={isMobile?{width: '110px',whiteSpace: 'nowrap', textAlign:'start', overflow: 'hidden', textOverflow: 'ellipsis'}:undefined} className="mt-2 text-center ">{p.website}</h4>
                                                            <span className="mb-1 text-center"><small className="text-white">{convertState(p.state)}</small></span>
                                                        </div>
                                                        <div className="text-center">
                                                            <div className="mt-1 mb-2 d-flex flex-row justify-content-around">
                                                                <div className="badge badge-sm bg-success">{p.departureCode}</div>
                                                                <div className="badge badge-sm bg-secondary"><i className="bi bi-arrow-right"></i></div>
                                                                <div className="badge badge-sm bg-primary">{p.destinationCode}</div>
                                                            </div>
                                                            <p className="card-text"><small className="text-body-secondary">{p.deliveryDate ? `expédié le ${formatDate(new Date(p.deliveryDate))}` : ''}</small></p>
                                                            <div style={{padding:'10px 10px'}}>
                                                                <div className="progress " style={{height:'15px'}}>
                                                                    <div className={`progress-bar ${p.progress === 100?'bg-success':'progress-bar-striped bg-primary progress-bar-animated'}`} role="progressbar" aria-label="Segment two"
                                                                         aria-valuenow={p.progress}
                                                                         aria-valuemin={0} aria-valuemax={100} style={{width: `${p.progress}%`}}>
                                                                        <div className="">{p.progress === 100?'Expédition terminée':`En cours d\'expédition (${p.progress}%)`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default TrackingContent;