import React from "react";
import Layout from "../components/layout/Layout";
import TrackingContent from "../components/trackingContent";
const Contact = (props: any) => {
    return <>
    <Layout title={'Suivre l\'état de vos colis afrisends'}>
        <TrackingContent {...props}/>
    </Layout>
    </>
}
export default Contact;